import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Album from "./pages/Album";


const routes = [
  {
    name: 'Home',
    path: '/',
    element: <Home/>,
  }, {
    name: 'About',
    path: '/about',
    element: <About/>,
  }, {
    name: 'Contact',
    path: '/contact',
    element: <Contact/>,
  }, {
    path: '/album/:s3Bucket',
    element: <Album/>,
  }
]

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <Router>
      <Header routes={routes}/>
      <Routes>
        {routes.map(({name, path, element}) => (
          <Route key={`$route-${name}`} path={path} element={element} />
        ))}
      </Routes>
    </Router>
  </ThemeProvider>
);

export default App;
