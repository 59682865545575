import { useEffect, useState, useRef } from 'react'
import AWS from 'aws-sdk'
import Flex from '../components/Flex'
import Typography from '../components/Typography'
import Pagination from '../components/Pagination'
import Popper from '../components/Popper'
import IconButton from '../components/IconButton'
import { useParams } from 'react-router-dom'
import albums from '../Albums.json'
import { useSearchParams } from 'react-router-dom'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

const DEFAULT_PER_PAGE = 24 

const Album = () => {
  const { s3Bucket } = useParams()
  const pageCount = useRef(0)

  const title = albums.filter((a) => a.s3Bucket === s3Bucket)[0].title
  const [objects, setObjects] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedImg, _setSelectedImg] = useState(searchParams.get('selectedImg'))
  const setSelectedImg = (value) => {
    _setSelectedImg(value)
    if(value) {
      setSearchParams({
        ...searchParams,
        selectedImg: value
      })
    } else {
      searchParams.delete('selectedImg')
      setSearchParams(searchParams)
    }
  }
  const handleNext = () => {
    let index = objects.findIndex(obj => obj.Key === selectedImg) + 1 
    if(index >= objects.length) {
      index = 0
    }
    setSelectedImg(objects[index].Key)
  }
  const handlePrev = () => {
    let index = objects.findIndex(obj => obj.Key === selectedImg) - 1 
    if(index < 0) {
      index = objects.length - 1
    }
    setSelectedImg(objects[index].Key)
  }
  const [perPage, _setPerPage] = useState(searchParams.get('perPage') || DEFAULT_PER_PAGE)
  const setPerPage = (value) => {
    _setPerPage(value)
    setSearchParams({
      ...searchParams,
      perPage: value,
    })
  }
  const [page, _setPage] = useState(parseInt(searchParams.get('page') || 1, 10))
  const setPage = (_event, value) => {
    _setPage(value)
    setSearchParams({
      ...searchParams,
      page: value,
    })
  }

  useEffect(() => {
    // Configure AWS SDK with Cognito Identity Pool
    AWS.config.update({
      region: process.env.REACT_APP_BUCKET_REGION,
    })

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    })

    // Refresh credentials to ensure they are properly loaded
    AWS.config.credentials.get((err) => {
      if (err) {
        console.error("Error loading credentials", err)
        return
      }

      // Initialize AWS S3 after credentials are loaded
      const s3 = new AWS.S3()

      // List Objects from a specific bucket
      const params = {
        Bucket: s3Bucket,
      }

      s3.listObjects(params, (err, data) => {
        if (err) {
          console.log("Error", err)
        } else {
          const filtered = data.Contents.filter(({Key})=> Key.includes('-thumbnail'))
          pageCount.value = Math.ceil(filtered.length / (perPage))
          setObjects(filtered)
        }
      })
    })
  }, [s3Bucket, perPage])
  return (
    <>
      <Flex style={{ width: '100%', justifyContent: 'center'}}>
        <Typography variant="h2" >{title}</Typography>
      </Flex>
      {pageCount.value > 1 ? (
        <Flex style={{justifyContent: 'center'}}>
          <Pagination count={pageCount.value} color="primary" page={page} onChange={setPage}/>
        </Flex>
      ) : (
        null
      )}
      <Flex style={{ padding: '32px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {objects.slice((page-1)*perPage, page*perPage).map((object) => (
          <img
            key={object.Key}
            src={`https://s3.amazonaws.com/${s3Bucket}/${object.Key}`} 
            alt={object.Key}
            style={{ height: '200px', padding: '4px', cursor: 'zoom-in' }}
            onClick={() => setSelectedImg(object.Key)}
            loading="lazy"
          />
        ))}
      </Flex>
      {pageCount.value > 1 ? (
        <Flex style={{justifyContent: 'center', marginBottom: '16px'}}>
          <Pagination count={pageCount.value} color="primary" page={page} onChange={setPage}/>
        </Flex>
      ) : ( 
        null
      )}
      <Popper open={!!selectedImg}>
        <Flex style={{
          background: 'black',
          height: '100vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {selectedImg && (
            <>
              <IconButton color="primary" onClick={handlePrev}>
                <ArrowBack/>
              </IconButton> 
              <img 
                src={`https://s3.amazonaws.com/${s3Bucket}/${selectedImg.replace('-thumbnail', '')}`} 
                alt={selectedImg}
                style={{
                  maxHeight: '90vh',
                  maxWidth: '90vw',
                  cursor: 'zoom-out'
                }}
                onClick={() => setSelectedImg()}
                loading="lazy"
              />
              <IconButton color="primary" onClick={handleNext}>
                <ArrowForward/>
              </IconButton> 
            </>
          )}
        </Flex>
      </Popper>
    </>
  )
}

export default Album
