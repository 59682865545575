import Box from '../components/Box'
import Typography from '../components/Typography'
import photo from '../assets/about/heather-hpr-2024.jpg'

const Paragraph = ({children}) => (
    <Typography variant="h3" style={{marginBottom: 32}}>
      {children}
    </Typography>
)
const About = () => (
  <Box style={{
    display: 'flex',
    paddingLeft: '16px',
    paddingRight: '16px',
  }}>
    <img src={photo} alt='Heather McClaine' style={{
      // width: '50vw'
      height: '800px'
    }}/>
    <Box style={{ paddingLeft: 32}}>
      <Paragraph>
        Hi, I'm Heather! 
      </Paragraph>
      <Paragraph>
        I'm a photographer based in beautiful Colorado, where I focus on capturing the wild moments of both nature and motorsports. From the breathtaking landscapes and wildlife to the adrenaline-filled rush of racing, I love freezing those dynamic, untamed moments in time. 
      </Paragraph>
      <Paragraph>
        When I'm not behind the lens, I'm a busy mom, finding balance between family life, work, and my passion for photography. Whether it's the roar of an engine or the quiet grace of wildlife, I aim to bring my unique perspective to every shot. 
      </Paragraph>
      <Paragraph>
        Thanks for stopping by, and I can't wait to share my journey with you!
      </Paragraph>
    </Box>
  </Box>
)
export default About